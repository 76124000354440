<template>
  <div>
    <vs-card>
      <vs-button class="mr-6" @click="showNewUserFormData()" color="dark"
        >Novo Usuário</vs-button
      >
      <vs-table ref="table" pagination :max-items="20" search :data="users">
        <template slot="thead">
          <vs-th sort-key="name">Usuário</vs-th>
          <vs-th sort-key="email">E-mail</vs-th>
          <vs-th sort-key="ambevId">AmbevId</vs-th>
          <vs-th sort-key="profile">Perfil</vs-th>
          <vs-th sort-key="unit">Unidade</vs-th>
          <vs-th>Ações</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                {{ tr.email }}
              </vs-td>
              <vs-td>
                {{ tr.ambevId }}
              </vs-td>
              <vs-td>
                {{ tr.profile.name }}
              </vs-td>
              <vs-td> {{ tr.unit.name }} - {{ tr.unit.region }} </vs-td>
              <vs-td>
                <div class="flex">
                  <!-- <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="editItem(tr.uuid)"
                    color="warning"
                    class="mr-1"
                  ></vs-button>-->
                  <vs-button
                    v-if="tr.active"
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-trash"
                    @click="removeItem(tr.uuid)"
                    color="danger"
                  ></vs-button>
                  <p v-else>
                    <a href="#" @click="updateUser(tr.uuid)">Reativar</a>
                  </p>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vs-card>
    <vs-popup :active.sync="showNewUserForm" title="Novo usuário">
      <vs-card>
        <fieldset class="fieldErrors" v-if="errors.count() > 0">
          <legend>Atenção aos erros do formulário:</legend>
          <ul>
            <li
              v-for="(error, index) in errors.all()"
              :key="index"
              class="alert"
            >
              {{ error }}
            </li>
          </ul>
        </fieldset>
        <form>
          <p>* Nome</p>
          <vs-input
            v-validate="'required'"
            name="name"
            v-model="user.name"
            class="w-full mb-6"
            data-vv-as="Nome"
          />

          <p>* E-mail</p>
          <vs-input
            v-validate="'required|email'"
            name="email"
            v-model="user.email"
            class="w-full mb-6"
          />

          <p>* Senha</p>
          <vs-input
            v-validate="'required'"
            name="password"
            type="password"
            v-model="user.password"
            class="w-full mb-6"
            data-vv-as="Senha"
          />

          <p>* Ambev-Id</p>
          <vs-input
            v-validate="'required|numeric'"
            name="ambevId"
            v-model="user.ambevId"
            class="w-full mb-6"
          />

          <p>* Data de nascimento</p>
          <vs-input
            v-validate="'required'"
            name="birthdate"
            v-model="user.birthdate"
            class="w-full mb-6"
            type="date"
            data-vv-as="Data de Nascimento"
          />
          <p>
            * Perfil
            <select
              v-validate="'required'"
              name="profile"
              v-model="user.profile"
              label="Perfil"
              class="w-full mb-6 select1"
            >
              <option
                :key="index"
                :value="item.id"
                v-for="(item, index) in profileOptions"
              >
                {{ item.name }}
              </option>
            </select>
          </p>

          <p>
            * Unidade
            <select
              v-validate="'required'"
              name="unitId"
              v-model="user.unitId"
              label="Unidade"
              class="w-full mb-6 select1"
              data-vv-as="Unidade"
            >
              <option
                :key="index"
                :value="item.id"
                v-for="(item, index) in unitOptions"
              >
                {{ item.name }}
              </option>
            </select>
          </p>
          <vs-button @click="submitUser()" class="mr-6"
            >Criar Usuário</vs-button
          >
        </form>
      </vs-card>
    </vs-popup>
  </div>
</template>

<script>
import siriusAPI from "../services";
import { notifyError, notifySuccess } from "../utils/helpers";
export default {
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        ambevId: "",
        profile: 1,
        role: "Operador",
        birthdate: "",
        unitId: null,
      },
      users: [],
      unitOptions: [],
      profileOptions: [],
      error: "",
      showNewUserForm: false,
    };
  },

  mounted() {
    this.loadUnits();
    this.loadProfiles();
    this.loadUsers();
  },

  methods: {
    loadUsers() {
      this.$vs.loading();
      new siriusAPI()
        .getUsers()
        .then((res) => {
          this.users = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de usuários " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    removeItem(uuid) {
      this.$vs.loading();
      new siriusAPI()
        .removeUser(uuid)
        .then(() => {
          this.$vs.loading.close();
          notifySuccess(this, "Usuário removido com sucesso!");
          this.loadUsers();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar excluir usuário: " + error);
        });
    },

    showNewUserFormData() {
      this.showNewUserForm = true;
    },

    resetUser() {
      this.user = {
        name: "",
        email: "",
        password: "",
        ambevId: "",
        profile: 1,
        role: "Operador",
        birthdate: "",
        regionalId: "",
        unitId: "",
      };
    },
    loadUnits() {
      new siriusAPI()
        .getUnits()
        .then((res) => {
          this.unitOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de unidades " + error
          );
        });
    },
    loadProfiles() {
      new siriusAPI()
        .getProfiles()
        .then((res) => {
          this.profileOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de perfis " + error
          );
        });
    },

    submitUser() {
      this.$validator.validate().then((valid) => {
        if (!valid) {
          notifyError(this, "Corrigir os erros apresentados.");
          return;
        } else {
          const user = this.user;
          this.$vs.loading();
          new siriusAPI()
            .addUser(user)
            .then(() => {
              this.$vs.loading.close();
              this.showNewUserForm = false;
              notifySuccess(this, "Usuário cadastrado com sucesso!");
              this.resetUser();
              this.loadUsers();
            })
            .catch((error) => {
              this.$vs.loading.close();
              notifyError(
                this,
                "Erro ao tentar cadastrar um novo usuário: " +
                  error.response.data.message
              );
            });
        }
      });
    },

    updateUser(uuid) {
      this.$vs.loading();
      const data = {
        active: true,
      };
      new siriusAPI()
        .updateUser(data, uuid)
        .then(() => {
          this.$vs.loading.close();
          notifySuccess(this, "Usuário reativado com sucesso!");
          this.loadUsers();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(
            this,
            "Erro ao tentar reativar 0 usuário: " + error.response.data.message
          );
        });
    },
  },
};
</script>
<style scoped>
.radio-space {
  padding-right: 0.5em;
}
ul {
  margin-bottom: 0.8em;
}
.alert {
  color: #f00;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
</style>
